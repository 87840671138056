import React, { Fragment } from "react";
import SectionTitle from "./SectionTitle";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { MdOutlineAutoGraph } from "react-icons/md";
import SingleDiv from "./SingleDiv";
import { BsLightning } from "react-icons/bs";

const data = [
  {
    id: 1,
    title: "Qualitiful Education",
    icon: <HiMiniComputerDesktop />,
    description:
      "CSB offers a practical curriculum, taught by ex-FAANG engineers and domain experts, to elevate your coding skills to global industry standards.",
  },
  {
    id: 2,
    title: "Hire Top 1% of Talent",
    icon: <MdOutlineAutoGraph />,
    description:
      "Hire top 1% of talent at a fraction of the cost and 90% faster. We will take care of all the compliances and paperwork.",
  },
  {
    id: 3,
    title: "Get High-Paid Remote Jobs",
    icon: <BsLightning />,
    description:
      "Get access to high-paid remote jobs from Bangladesh, all from the comfort of your home. We’ll assist you with the interview process and paperwork",
  },
];

const WhyCSBSection = () => {
  return (
    <section className="py-12 max-w-screen-xl mx-auto">
      <div data-aos="fade-up-right">
        <SectionTitle title="Why CSB ?" />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-4 lg:px-0 py-8">
        {data?.map((item) => {
          return (
            <Fragment key={item?.id}>
              <SingleDiv {...item} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default WhyCSBSection;
