import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMenuAlt3 } from "react-icons/hi";
import { useAuth } from "../Authentication/AuthContext";
import { Button, Menu, MenuItem, IconButton } from "@mui/material";
import LoginModal from "../Login/LoginModal";
import { styled } from "@mui/material/styles";

const GlowButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  padding: "8px 16px",
  background: "linear-gradient(45deg, #be0aff 0%, #000000 100%)",
  color: "white",
  transition: "box-shadow 0.3s ease-in-out, border 0.3s ease-in-out",
  border: "2px solid #fb0094",
  "&:hover": {
    boxShadow: "0 0 20px #fb0094, 0 0 40px #fb0094",
  },
}));

const Navbar = () => {
  const { currentUser, signOutUser } = useAuth();
  const [mobileNav, setMobileNav] = useState(false);
  const [header, setHeader] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const handleOpenLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleLogout = async () => {
    try {
      await signOutUser();
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  const menu = [
    { id: 2, text: "Courses", to: "/courses" },
    // { id: 3, text: "For Companies", to: "/employer" },
    // { id: 4, text: "For Developers", to: "/employee" },
    { id: 6, text: "Hackathon", to: "/hackathon" },
  ];

  if (currentUser) {
    menu.push({
      id: 1,
      text: "Blog",
      to: "/blog",
    });
    // if (location.pathname === "/employee") {
    //   menu.push({ id: 5, text: "Roadmap", to: "/roadmap" });
    // }
    menu.push({ id: 5, text: "Roadmap", to: "/roadmap" });
  }

  const handleClick = () => {
    setMobileNav(!mobileNav);
  };

  const changeHeader = () => {
    setHeader(window.scrollY >= 80);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeHeader);
    return () => {
      window.removeEventListener("scroll", changeHeader);
    };
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <header
      className={`fixed w-full z-10 ${
        header ? "bg-white shadow-md" : "bg-transparent"
      }`}
    >
      <nav className="max-w-screen-xl mx-auto px-4 flex items-center justify-between h-16">
        <div className="flex items-center">
          <Link to="/">
            <img src="./logo.png" alt="logo" className="w-12 h-12" />
          </Link>
          <Link to="/">
            <h1 className="hidden md:block text-xl font-extrabold text-csb-500 ml-4">
              CSB
            </h1>
            <h1 className="block md:hidden text-xl font-extrabold text-csb-500 ml-4">
              CSB
            </h1>{" "}
          </Link>
          <div className="hidden md:flex items-center space-x-4 ml-4">
            {menu.map((item) => (
              <Link key={item.id} to={item.to} className="text-sm text-black">
                {item.text}
              </Link>
            ))}
            <Menu
              id="developer-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
              <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
            </Menu>
          </div>
        </div>
        <div className="hidden md:flex items-center space-x-4">
          {location.pathname === "/employer" ? (
            <GlowButton variant="contained">Request a demo 🌟</GlowButton>
          ) : location.pathname === "/employee" ? (
            <>
              <GlowButton variant="contained">Join CSB 🚀</GlowButton>
            </>
          ) : null}
          {currentUser ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
            >
              Log Out
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenLoginModal}
            >
              Login
            </Button>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <IconButton onClick={handleClick} aria-label="Open menu">
            <HiMenuAlt3 className="w-10 h-10 text-gray-700" />
          </IconButton>
        </div>
      </nav>
      {mobileNav && (
        <div className="md:hidden bg-white shadow-lg rounded-lg border border-gray-300 py-2 px-4">
          <ul>
            {menu.map((item) => (
              <Link
                key={item.id}
                to={item.to}
                className="block text-gray-600 text-sm py-2"
                onClick={handleClick}
              >
                {item.text}
              </Link>
            ))}
          </ul>
          <div className="mt-4">
            {currentUser ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
                fullWidth
              >
                Log Out
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenLoginModal}
                fullWidth
              >
                Login
              </Button>
            )}
          </div>
        </div>
      )}
      <LoginModal open={openLoginModal} handleClose={handleCloseLoginModal} />
    </header>
  );
};

export default Navbar;
