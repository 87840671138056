import React from "react";
import { Grid, Container } from "@mui/material";
import CourseCard from "./CourseCard";

const courses = [
  {
    title: "DSA/Technical Interview",
    price: "8000",
    contents: [
      "Hybrid",
      "Recorded Lectures",
      "Live classes",
      "Duration: 6 weeks",
      "1-1 Problem Support (via Discord)",
    ],
    curriculumLink:
      "https://docs.google.com/document/d/1jk8unG54peVU-PTEoWVtKEuh5k17CyJ_9RcS_xrnPeI/edit?usp=sharing",
    spreadsheetLink: "https://forms.gle/VFwE657K4qJBkFAy6",
  },
  {
    title: "System Design",
    price: "10000",
    contents: [
      "Hybrid",
      "Recorded Lectures",
      "Live classes",
      "Duration: 6 weeks",
      "1-1 Problem Support (via Discord)",
    ],
    curriculumLink:
      "https://docs.google.com/document/d/10qkvIXDTflmr2T6ku7RQuaddqw3aBjKgYr2Xz-LoMk0/edit?usp=sharing",
    spreadsheetLink: "https://forms.gle/7ete9JJh1iH5ipUt8",
  },

  // {
  //   title: "Live Web Development",
  //   price: "20000",
  //   contents: [
  //     "Interactive Online Sessions",
  //     "3 Month Duration",
  //     "1-1 Problem Support (via Discord)",
  //   ],
  //   curriculumLink: "/dsa",
  //   spreadsheetLink: "TBD",
  // },
  // {
  //   title: "Live Android App Development",
  //   price: "20000",
  //   contents: [
  //     "Interactive Online Sessions",
  //     "3 Month Duration",
  //     "1-1 Problem Support (via Discord)",
  //   ],
  //   curriculumLink: "/dsa",
  //   spreadsheetLink: "https://forms.gle/WsNA2bpJfM8QMXSX7",
  // },

  // {
  //   title: "Live Machine Learning",
  //   price: "30000",
  //   contents: [
  //     "Interactive Online Sessions",
  //     "4 Month Duration",
  //     "1-1 Problem Support (via Discord)",
  //   ],
  //   curriculumLink: "#curriculum-link",
  //   spreadsheetLink: "https://forms.gle/scgnd2AhSYZfTPJA7",
  // },

  // {
  //   title: "Big Tech Interview Bundle",
  //   price: "50000",
  //   contents: [
  //     "DSA & System Design Course",
  //     "Database of recruiters & managers",
  //     "3 FAANG Mock Interviews",
  //   ],
  //   curriculumLink: "#curriculum-link",
  //   spreadsheetLink: "https://forms.gle/scgnd2AhSYZfTPJA7",
  // },
  // {
  //   title: "360 Career Accelerator",
  //   price: "200,000",
  //   contents: [
  //     "Active Resume building with experts",
  //     "Mock Interviews, Profile Makeover",
  //     "6 Month Job Or Guaranteed Refund",
  //   ],
  //   curriculumLink: "#curriculum-link",
  //   spreadsheetLink: "https://forms.gle/VFwE657K4qJBkFAy6",
  // },
];

const Courses = () => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} style={{ marginTop: "10%" }}>
          {courses.map((course, index) => (
            <CourseCard key={index} course={course} />
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Courses;
