import React from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import SectionTitle from "./SectionTitle";

const testimonials = [
  {
    name: "Ashab Asir",
    role: "CS Student in Bangladesh",
    avatar: "../../testimonials/ashab.png",
    quote:
      "CSB provided me with invaluable career advice, specifically regarding the data science industry. Throughout the session, Adib demonstrated a genuine and friendly demeanor, making the conversation comfortable and engaging. His dedication to bridging the knowledge gap among computer science students in our country was evident as he passionately guided me through various aspects of career development. One of the highlights of the session was the comprehensive advice on improving my LinkedIn profile and resume. His insights were not only insightful but also practical, providing me with actionable steps to enhance my professional presence",
  },
  {
    name: "Mishkat Hossain",
    role: "CS Student in Bangladesh",
    avatar: "../../testimonials/miskatul.png",
    quote:
      "CSB’s guidance was invaluable in helping me navigate the complexities of my chosen field and provided practical suggestions that I could immediately implement. Adib’s extensive knowledge and experience were evident throughout the session, and I felt confident in the advice he offered. What stood out the most about Adib was his genuine passion for helping individuals succeed in their careers. His positive demeanor and encouragement motivated me to explore new possibilities and even consider alternative paths. I left the session feeling inspired and equipped with a clearer vision of my professional journey",
  },
  {
    name: "Raiyana Rahman",
    role: "CS Student in Canada",
    avatar: "../../testimonials/raiyana.png",
    quote:
      "The session with CSB was an absolute gold mine of knowledge! Adib suggested strategies for obtaining internships more quickly. Throughout the session, he suggested a wealth of insights on not just landing internships but also crafting a roadmap for success throughout my CS degree. Adib’s friendly nature, coupled with a sincere dedication to help students, transformed the session into an exceptionally informative and engaging experience. His dedication to closing the knowledge gap among Bangladeshi CS majors became even more apparent after he answered all my questions with such passion. Grateful for the invaluable insights and massive thank you for the fantastic career advice. I really appreciate it.  I'm excited to follow the guidance and advance in my career",
  },
  {
    name: "Musa Kajem Reza",
    role: "Non-CS Student in Bangladesh",
    avatar: "../../testimonials/musa.png",
    quote:
      "After watching your video, I started coding again. I tried a lot but couldn't get it right. I searched for it until I found it through your video. Thanks for the amazing roadmap for those who are from non-CSE backgrounds",
  },
  {
    name: "Ajharul Islam Aunik",
    role: "CS Student in the US",
    avatar: "../../testimonials/aunik.png",
    quote:
      "CSB provided invaluable insights in preparation for the summer 2025 SWE internship, especially focusing on my GPA and leadership roles. His advice on organizing a Hackathon at my college, making socially impactful projects, and insights on micro-optimizing my life was incredibly helpful. Thank you so much!",
  },
  // {
  //   name: "Anindya Das",
  //   role: "CS Student in Bangladesh",
  //   avatar: "../../testimonials/anindya.png",
  //   quote:
  //     "After hearing about my background, Adib shared some realistic plans/roadmap to achieve my goal based on academics. Besides, he gave me some suggestions on my Web Development journey. Overall, it is a great experience & I greatly appreciate Coding Shikhbe Bangladesh's support",
  // },
  {
    name: "Anika Tabassum",
    role: "QA Engineer at Riseup Labs",
    avatar: "../../testimonials/anika.png",
    quote:
      "Adib from CSB is an amazing person such a good listener, quenched my thirst for knowledge talking to you. I was really happy with the fact that you are ready to give more time to us whenever it is needed. I wish CSB the very best.",
  },
  // {
  //   name: "Lamia Noboni",
  //   role: "High-School Student",
  //   avatar: "../../testimonials/unknown.png",
  //   quote:
  //     "Adib from CSB was extremely helpful and strategic in giving me personalized suggestions regarding my CV/aim and other aspects. I can't thank him enough for this session",
  // },
];

const TestimonialCard = ({ testimonial }) => (
  <Box
    sx={{
      p: 4,
      border: "1px solid #e5e7eb",
      borderRadius: "0.75rem",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    }}
  >
    <Typography variant="body1" className="text-gray-600 mb-4">
      &ldquo;{testimonial.quote}&rdquo;
    </Typography>
    <div className="flex items-center">
      <Avatar alt={testimonial.name} src={testimonial.avatar} />
      <div className="ml-4 pt-4">
        <Typography variant="subtitle1" className="font-medium text-gray-900">
          {testimonial.name}
        </Typography>
        <Typography variant="body2" className="text-gray-500">
          {testimonial.role}
        </Typography>
      </div>
    </div>
  </Box>
);

const TestimonialsSection = () => (
  <section className="bg-white py-16">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <SectionTitle title="Testimonials" />
      <h2 className="text-3xl leading-9 font-extrabold text-gray-900 text-center mb-8 font-display">
        What students are saying about us...
      </h2>
      <Grid container spacing={{ xs: 2, md: 4 }} className="justify-center">
        {testimonials.map((testimonial) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={testimonial.name}
            className="flex justify-center"
          >
            <TestimonialCard testimonial={testimonial} />
          </Grid>
        ))}
      </Grid>
    </div>
  </section>
);

export default TestimonialsSection;
