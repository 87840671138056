import React from "react";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <main className="max-w-screen-xl mx-auto px-4">
      {/* parent div */}
      <div className="flex flex-col pt-32 lg:items-center lg:justify-between lg:flex-row lg:pt-12">
        {/* main text and button */}
        <div
          data-aos="fade-right"
          className="flex flex-col items-start space-y-4 lg:w-1/2"
        >
          <h1 className="text-black text-3xl sm:text-4xl md:text-5xl lg:text-7xl xl:text-8xl font-extrabold tracking-tight leading-tight">
            Empowering <br /> Next_ <br />{" "}
            <span className="text-pink-500">Generation</span>
          </h1>
          <div className="flex space-x-4 pt-4">
            {/* <button
              className="bg-csb-500 px-5 py-3 text-sm md:text-base lg:text-lg text-white transition duration-300 rounded-full hover:bg-csb-600"
              onClick={() => navigate("/company")}
            >
              For Companies
            </button>
            <button
              className="bg-csb-500 px-5 py-3 text-sm md:text-base lg:text-lg text-white transition duration-300 rounded-full hover:bg-csb-600"
              onClick={() => navigate("/developer")}
            >
              For Developers
            </button> */}
            <button
              className="bg-csb-500 px-5 py-3 text-sm md:text-base lg:text-lg text-white transition duration-300 rounded-full hover:bg-csb-600"
              onClick={() => navigate("/courses")}
            >
              Start Learning
            </button>
          </div>
        </div>

        {/* vector gfx */}
        <div className="relative lg:w-1/2 mt-12 lg:mt-0">
          <div className="absolute inset-0 w-full h-full object-cover">
            <img
              src="../../../hero_bg.svg"
              className="absolute inset-0 w-full h-full object-cover"
              alt=""
            />
          </div>
          <div data-aos="fade-left" className="relative">
            <img
              className="w-full h-full object-cover"
              src="../../../heroimg.svg"
              alt="hero section vector"
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
