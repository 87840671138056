import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const CourseCard = ({ course }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Card raised>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {course.title}
        </Typography>

        <Typography
          variant="h5"
          color="primary"
          align="center"
          style={{ marginBottom: "8px" }}
        >
          {course.price} Taka
        </Typography>

        <List dense>
          {course.contents.map((content, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleOutlineIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={content} />
            </ListItem>
          ))}
        </List>

        <Button
          variant="contained"
          color="secondary"
          fullWidth
          href={course.curriculumLink}
          style={{ marginBottom: 8 }}
        >
          See Curriculum
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          href={course.spreadsheetLink}
          style={{ marginBottom: 8 }}
        >
          Buy Now
        </Button>
      </CardContent>
    </Card>
  </Grid>
);

export default CourseCard;
