import React, { useState, useEffect } from "react";
import SectionTitle from "./SectionTitle";
import { Box } from "@mui/material";

function AsSeenOnSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const logos = [
    {
      src: "../../newspapers/bpost.png",
      link: "https://bangladeshpost.net/posts/hackcsb-ignites-innovation-among-bangladesh-s-tech-students-144881",
    },
    {
      src: "../../newspapers/financialE.png",
      link: "https://thefinancialexpress.com.bd/sci-tech/how-a-bangladeshi-hackathon-is-inspiring-a-generation",
    },
    {
      src: "../../newspapers/dhaka.png",
      link: "https://www.dhakatribune.com/bangladesh/education/357156/bangladesh%E2%80%99s-tech-sector-energized-by",
    },
    {
      src: "../../newspapers/microsoft.png",
      link: "https://www.msn.com/en-us/news/other/how-adib-is-transforming-education-and-media/ar-BB1nTnxZ?disableErrorRedirect=true&infiniteContentCount=0&apiversion=v2&noservercache=1&domshim=1&renderwebcomponents=1&wcseo=1&batchservertelemetry=1&noservertelemetry=1",
    },
  ];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 3000);

    return () => clearInterval(slideInterval);
  }, [logos.length]);

  const getVisibleLogos = () => {
    return [
      logos[currentIndex],
      logos[(currentIndex + 1) % logos.length],
      logos[(currentIndex + 2) % logos.length],
    ];
  };

  return (
    <Box className="bg-white py-8">
      <SectionTitle title="Seen On..." />
      <div className="flex items-center justify-center gap-8 overflow-hidden">
        {getVisibleLogos().map((logo, index) => (
          <a
            key={index}
            href={logo.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-[280px] h-[200px] overflow-hidden"
          >
            <img
              src={logo.src}
              alt={`Logo ${currentIndex + index + 1}`}
              className="bg-transparent transition-opacity duration-500 ease-in-out object-contain max-w-[280px] max-h-[200px]"
            />
          </a>
        ))}
      </div>
    </Box>
  );
}

export default AsSeenOnSection;
